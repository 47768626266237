import {SLACK_CLIENT_ID, WEBSITE_ROOT} from "../constants";

export function generateGuid() {
    function s4() {
        return Math.floor((1 + Math.random()) * 0x10000)
            .toString(16)
            .substring(1);
    }
    return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();
}

const userScope = 'channels:read,channels:write,emoji:read,users.profile:read';
const botScope = 'app_mentions:read,channels:history,chat:write,chat:write.customize,im:history,im:write,users:read'

export const installLink = `https://slack.com/oauth/v2/authorize?client_id=${SLACK_CLIENT_ID}&scope=${botScope}&user_scope=${userScope}&redirect_uri=${WEBSITE_ROOT}/installApp`;

export const loginLink = `https://slack.com/oauth/authorize?scope=${userScope}&client_id=${SLACK_CLIENT_ID}&redirect_uri=${WEBSITE_ROOT}/loginFromSlack`;