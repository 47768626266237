import React from 'react';
import {APP_NAME} from "../constants";
import {Link} from "react-router-dom";

const footerSectionStyle = {
    backgroundColor: "rgb(239, 243, 247)",
    minHeight: "100px",
    paddingTop: "2rem",
    paddingBottom: "2rem",
};

const PublicFooter = () => {
    return <div className="container-fluid" style={footerSectionStyle}>
        <div className="container">
            <div className="row text-center">
                <div className="col-12 col-md-3">
                    <span className="nav-link">© {new Date().getFullYear()} {APP_NAME}</span>
                </div>
                <div className="col-12 col-md-3">
                    <Link className="nav-link" to="/terms">Terms & Conditions</Link>
                </div>
                <div className="col-12 col-md-3">
                    <Link className="nav-link" to="/privacy">Privacy Policy</Link>
                </div>
                <div className="col-12 col-md-3">
                    <Link className="nav-link" to="/support">Support</Link>
                </div>
            </div>
        </div>
    </div>
}

export default PublicFooter;