import React, {lazy, Suspense} from "react";
import {BrowserRouter as Router, Switch, Route} from "react-router-dom";
import LoginFromSlack from "../Pages/LoginFromSlack";
import AuthProvider from "../hooks/AuthProvider";
import AuthContext from "../hooks/AuthContext";
import PublicPage from "../Pages/Public/PublicPage";
import PrivatePage from "../Pages/Private/PrivatePage";
import InstallApp from "../Pages/InstallApp";
import withTracker from "./withTracker";
import PublicLoadingPage from "../Pages/Public/PublicLoadingPage";
import PrivateLoadingPage from "../Pages/Private/PrivateLoadingPage";

const Home = lazy(() => import("../Pages/Public/Home"));
const About = lazy(() => import("../Pages/Public/About"));
const Terms = lazy(() => import("../Pages/Public/TermsAndConditions"));
const PrivacyPolicy = lazy(() => import("../Pages/Public/PrivacyPolicy"));
const Support = lazy(() => import("../Pages/Public/Support"));
const Pricing = lazy(() => import("../Pages/Public/Pricing"));
const Error = lazy(() => import("../Pages/Public/Error"));
const Dashboard = lazy(() => import("../Pages/Private/Dashboard"));
const Settings = lazy(() => import("../Pages/Private/Settings"));
const Account = lazy(() => import("../Pages/Private/Account"));
const Setup = lazy(() => import("../Pages/Private/Setup"));


const PublicComponent = Component => {
    return props => (
        <Suspense fallback={<PublicLoadingPage />}>
            <PublicPage>
                <Component {...props} />
            </PublicPage>
        </Suspense>
    );
};

const PrivateComponent = Component => {
    return props => (
        <Suspense fallback={<PrivateLoadingPage />}>
            <PrivatePage>
                <Component {...props} />
            </PrivatePage>
        </Suspense>
    );
};

const AppRouter = () => {
    return (
        <AuthProvider>
            <AuthContext.Consumer>
                {context =>
                    context.userToken ? (
                        <Router>
                            <Switch>
                                <Route path="/" exact component={withTracker(PrivateComponent(Dashboard))}/>
                                <Route
                                    path="/settings"
                                    exact
                                    component={withTracker(PrivateComponent(Settings))}
                                />
                                <Route
                                    path="/setup"
                                    exact
                                    component={withTracker(PrivateComponent(Setup))}
                                />
                                <Route
                                    path="/account"
                                    exact
                                    component={withTracker(PrivateComponent(Account))}
                                />
                            </Switch>
                        </Router>
                    ) : (
                        <Router>
                            <Switch>
                                <Route path="/" exact component={withTracker(PublicComponent(Home))}/>
                                <Route path="/about" exact component={withTracker(PublicComponent(About))}/>
                                <Route
                                    path="/pricing"
                                    exact
                                    component={withTracker(PublicComponent(Pricing))}
                                />
                                <Route path="/error" exact component={withTracker(PublicComponent(Error))}/>
                                <Route path="/terms" exact component={withTracker(PublicComponent(Terms))}/>
                                <Route path="/privacy" exact component={withTracker(PublicComponent(PrivacyPolicy))}/>
                                <Route path="/support" exact component={withTracker(PublicComponent(Support))}/>
                                <Route path="/loginFromSlack" component={withTracker(LoginFromSlack)}/>
                                <Route path="/installApp" component={withTracker(InstallApp)}/>
                            </Switch>
                        </Router>
                    )
                }
            </AuthContext.Consumer>
        </AuthProvider>
    );
};

export default AppRouter;
