import React, {Fragment} from 'react'
import spinner from '../../images/branded-spinner.gif'
import PublicHeader from "../../Components/PublicHeader";

const PublicLoadingPage = () => (
    <Fragment>
        <PublicHeader/>
        <div className="container-fluid pt-2">
            <div className="row">
                <div className="col">
                    <div className="d-flex justify-content-center">
                        <img alt={"Spinning animation"} src={spinner}/>
                    </div>
                </div>
            </div>
        </div>
    </Fragment>
);

export default PublicLoadingPage;