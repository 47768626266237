import {API_ROOT} from "../constants";

export const handleInstallationOfApp = code =>
    fetch(`${API_ROOT}/auth/installApp/${code}`).then(res =>
        res.json()
    );

export const handleLoginFromSlack = code =>
    fetch(`${API_ROOT}/auth/login/${code}`).then(res =>
        res.json()
    );

export const getTeamSettings = accessToken =>
    fetch(`${API_ROOT}/settings`, {
        headers: {
            'x-access-token': accessToken
        }
    }).then(res =>
        res.json()
    );

export const getAccountSettings = accessToken =>
    fetch(`${API_ROOT}/account`, {
        headers: {
            'x-access-token': accessToken
        }
    }).then(res =>
        res.json()
    );

export const updateTeamSettings = (accessToken, token, tokenPlural, maxPerDay, timezone, botUserName, botLogoUrl, recipientMessage, tokenSingular) =>
    fetch(`${API_ROOT}/settings`, {
        method: 'POST',
        headers: {
            'x-access-token': accessToken,
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            token,
            'token_plural': tokenPlural,
            'token_singular': tokenSingular,
            'max_points_per_user_per_day': maxPerDay,
            'timezone_offset': timezone,
            'bot_username': botUserName,
            'bot_logo_url': botLogoUrl,
            'recipient_message': recipientMessage
        })
    }).then(res =>
        res.json()
    );

export const getLeaderboard = accessToken =>
    fetch(`${API_ROOT}/leaderboard`, {
        headers: {
            'x-access-token': accessToken
        }
    }).then(res => {
            return res.json()
        }
    ).catch(err => {
        throw new Error(err)
    });

export const getMonthlyLeaderboard = accessToken =>
    fetch(`${API_ROOT}/monthly_leaderboard`, {
        headers: {
            'x-access-token': accessToken
        }
    }).then(res => {
            return res.json()
        }
    ).catch(err => {
        throw new Error(err)
    });

export const getPhotoSignUrl = (accessToken, data) =>
    fetch(`${API_ROOT}/assets/sign-photo-upload`, {
        method: 'POST',
        headers: {
            'x-access-token': accessToken,
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
    }).then(res => {
            return res.json()
        }
    ).catch(err => {
        throw new Error(err)
    });
