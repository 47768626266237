import React, {useState} from 'react'
import { Link } from 'react-router-dom';
import logo from "../images/ThanksChatLogo.png";
import {APP_NAME} from "../constants";
import useAuth from "../hooks/useAuth";
import {Collapse, Nav, Navbar, NavbarToggler, NavItem} from "reactstrap";


const PrivateHeader = () => {
  const {setUserToken} = useAuth();

  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => setIsOpen(!isOpen);

  return (
    <Navbar light color="white" expand="md">
      <Link className="navbar-brand" to="/">
        <img src={logo} height="30" alt={`${APP_NAME} Logo`}/> {APP_NAME}
      </Link>
      <NavbarToggler onClick={toggle}/>
      <Collapse isOpen={isOpen} navbar>
        <Nav className="mr-auto" navbar/>
        <Nav navbar>
          <NavItem className="mr-3">
            <Link className="nav-link" to="/">Dashboard</Link>
          </NavItem>
          <NavItem className="mr-3">
            <Link className="nav-link" to="/settings">Settings</Link>
          </NavItem>
          <NavItem className="mr-3">
            <Link className="nav-link" to="/account">Account</Link>
          </NavItem>
          <NavItem className="mr-3">
            <Link className="nav-link" to="/" onClick={() => {
              setUserToken("")
            }}>Sign Out</Link>
          </NavItem>
        </Nav>
      </Collapse>
    </Navbar>
  );
};

export default PrivateHeader;