import { useContext } from 'react';
import AuthContext from "./AuthContext";

const useAuth = () => {
    const data = useContext(AuthContext);
    if (!data) {
        throw new Error('hook must be used within the corresponding provider');
    }
    return data;
};
export default useAuth;
