import React, { useState } from 'react'

import {
  Collapse,
  Nav,
  Navbar,
  NavbarToggler,
  NavItem,
} from "reactstrap";
import logo from "../images/ThanksChatLogo.png";
import {Link} from "react-router-dom";
import {APP_NAME} from "../constants";
import {loginLink} from "../utils/misc";


const PublicHeader = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => setIsOpen(!isOpen);

  return <Navbar light color="white" expand="md">
    <Link className="navbar-brand" to="/">
      <img src={logo} height="30" alt={`${APP_NAME} Logo`}/> {APP_NAME}
    </Link>
    <NavbarToggler onClick={toggle}/>
    <Collapse isOpen={isOpen} navbar>
      <Nav className="mr-auto" navbar />
      <Nav navbar>
        <NavItem className="mr-3">
          <Link className="nav-link" to="/">Home</Link>
        </NavItem>
        {/*<NavItem className="mr-3">*/}
        {/*  <Link className="nav-link" to="/about">How it works</Link>*/}
        {/*</NavItem>*/}
        <NavItem className="mr-3">
          <Link className="nav-link" to="/pricing">Pricing</Link>
        </NavItem>
        <NavItem className="mr-3">
          <a href={loginLink}>
            <img
              alt="Sign in with Slack"
              height="40"
              width="172"
              src="https://platform.slack-edge.com/img/sign_in_with_slack.png"
              srcSet="https://platform.slack-edge.com/img/sign_in_with_slack.png 1x, https://platform.slack-edge.com/img/sign_in_with_slack@2x.png 2x"
            />
          </a>
        </NavItem>
      </Nav>
    </Collapse>
  </Navbar>
};

export default PublicHeader;