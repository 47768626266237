import React from 'react';
import PublicHeader from "../../Components/PublicHeader";
import PublicFooter from "../../Components/Footer";

const PublicPage = ({children}) => <div>
    <PublicHeader/>
    {children}
    <PublicFooter/>
</div>;

export default PublicPage;