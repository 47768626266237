import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
// import * as serviceWorker from './serviceWorker';
import AppRouter from "./Routers/AppRouter";
import 'bootstrap/dist/css/bootstrap.min.css';

// ReactGA.initialize(GA_ID);
// ReactGA.pageview(window.location.pathname + window.location.search);

ReactDOM.render(<AppRouter />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
