import React, { useState } from "react";
import AuthContext from "./AuthContext";

const Provider = props => {
  const [state, setState] = useState(sessionStorage.getItem("userToken") || "");
  return (
    <AuthContext.Provider
      value={{
        userToken: state,
        setUserToken: token => {
          setState(token);
          sessionStorage.setItem("userToken", token);
        }
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};

export default Provider;
