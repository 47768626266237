import React, {useContext, useEffect, useCallback, useState} from 'react';
import queryString from "query-string";
import AuthContext from "../hooks/AuthContext";
import {withRouter} from "react-router-dom";
import {handleInstallationOfApp} from "../services/kudosBotApi";
import {APP_NAME} from "../constants";
import PublicLoadingPage from "./Public/PublicLoadingPage";

const InstallApp = withRouter(({history, location}) => {
    const [error, setError] = useState(null);
    const {code} = queryString.parse(location.search);

    const {setUserToken} = useContext(AuthContext);

    const getAuthTokenFn = useCallback(() => {
        handleInstallationOfApp(code).then(json => {
            setUserToken(json.token);
            history.push('/setup')
        }).catch(err => {
            setError(err)
            history.push({
                pathname: '/',
                state: { error: `There was a problem setting up ${APP_NAME}. Please try again later.` }
            })
        })
    }, [code, setUserToken, history]);

    useEffect(() => {
        getAuthTokenFn()
    }, [getAuthTokenFn]);

    return error ? <div>Error</div> : <PublicLoadingPage />
});

export default InstallApp;