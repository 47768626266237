import React, {useContext, useEffect, useCallback, useState} from 'react';
import queryString from "query-string";
import AuthContext from "../hooks/AuthContext";
import {withRouter} from "react-router-dom";
import {handleLoginFromSlack} from "../services/kudosBotApi";
import PublicLoadingPage from "./Public/PublicLoadingPage";

const LoginFromSlack = withRouter(({history, location}) => {
    const [error, setError] = useState(null);
    const {code} = queryString.parse(location.search);

    const {setUserToken} = useContext(AuthContext);

    const getAuthTokenFn = useCallback(() => {
        handleLoginFromSlack(code).then(json => {
            setUserToken(json.token);
            history.push('/')
        }).catch(err => {
            setError(err)
            history.push({
                pathname: '/',
                state: { error: 'There was a problem logging in. Please try again later. If you are trying to set up an account, please press the "Add to Slack" button instead.' }
            })
        })
    }, [code, setUserToken, history]);

    useEffect(() => {
        getAuthTokenFn()
    }, [getAuthTokenFn]);

    return error ? <div>Error</div> : <PublicLoadingPage />
});

export default LoginFromSlack;